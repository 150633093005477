import {
  HomeIcon,
  ExploreIcon,
  SideNavQuestionMarkIcon,
  UserIcon,
  ZaidynLogo,
  SurveyQuestionsIcon,
  BespokeInsightIcon
} from "../assets/icons/svg";
import { logout } from "../services/ExperienceBankAPI";

import Icon from "@ant-design/icons";
import React from "react";
import "./Home.scss";
import { Dropdown } from "antd";

const logoutClick = async () => {
  try {
    //sessionStorage.setItem("loggedout", "yes");
    const payload = {
      user_id: localStorage.getItem("user_id"),
      logout_from: "logout",
    };

    await logout(payload); // Replace with your actual API call
    sessionStorage.removeItem('sessionId');

    window.location.replace(`${process.env.REACT_APP_IDM_LOGOUT_URL}`);
  } catch (error) {
    console.error("Error during logout:", error);
    // Handle error as needed
  }
};

export const ProductLogo = [
  {
    key: "zaidynLogo",
    value: (
      <React.Fragment>
        <div>
          {/* <Icon style={{ display: "inline-block" }} component={ZaidynLogo} /> */}
          <Icon component={ZaidynLogo} />
        </div>
      </React.Fragment>
    ),
  },
]

export const SideNavBarData = [

  {
    key: "/explore", //remove space after explore
    value: (
      <React.Fragment>
        <div>
          <Icon component={ExploreIcon} />
        </div>
        <div className="navBarItems">
          <span>Explore</span>
        </div>
      </React.Fragment>
    ),
  },
  {
    key: "/analysis-library",
    value: (
      <React.Fragment>
        <div>
          <Icon component={HomeIcon} />
        </div>
        <div className="navBarItems">
          <span>
            Filter and <br /> Report <br />
            Library
          </span>
        </div>
      </React.Fragment>
    ),
  },
  {
    key: "/survey-questions",
    value: (
      <React.Fragment>
        <div>
          <Icon component={SurveyQuestionsIcon} />
        </div>
        <div className="navBarItems">
          <span>
            Survey <br /> Questions
          </span>
        </div>
      </React.Fragment>
    ),
  },
  {
    key: "/bespoke-insights",
    value: (
      <React.Fragment>
        <div>
          <Icon component={BespokeInsightIcon} />
        </div>
        <div className="navBarItems">
          <span>
            Bespoke <br /> Insights
          </span>
        </div>
      </React.Fragment>
    ),
  },
];

const items = [
  {
    key: "1",
    label: (
      <a
        target="_self"
        rel="noopener noreferrer"
        className="dropdown-link"
        href={process.env.REACT_APP_IDM_MY_ACCOUNT_URL}
      >
        My Account
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        target="_self"
        rel="noopener noreferrer"
        className="dropdown-link"
        onClick={logoutClick}
        href={process.env.REACT_APP_IDM_LOGOUT_URL}
      >
        Logout
      </a>
    ),
  },
];

export const setting = [
  {
    key: "user",
    value: (
      <React.Fragment>
        <div>
          <Dropdown
            menu={{
              items,
            }}
            trigger={"click"}
          >
            <Icon component={UserIcon} />
          </Dropdown>
        </div>
      </React.Fragment>
    ),
  },
  {
    key: "helpCenter",
    value: (
      <React.Fragment>
        <div>
          <Icon component={SideNavQuestionMarkIcon} />
        </div>
        {/* ---- remove it later just for testing ---- */}
        {/* <div className="navBarItems">
          <a
            target="_self"
            rel="noopener noreferrer"
            className="dropdown-link"
            href={process.env.REACT_APP_ENV_SWITCH_URL}>
          </a>
          <span>EF</span>
        </div> */}
        {/* ----------- */}
      </React.Fragment>
    ),
  },
];
