import React, { useState, useEffect, useRef } from "react";
import "./SurveyQuestions.scss";
import { useSelector } from "react-redux";
import { Row, Col, Table, Space, Input, List, Divider, Spin } from "antd";
import { getSurveyQuestions } from "../../services/SurveyQuestionsAPI";
import debounce from "lodash/debounce";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setNavigationTab } from "../../reducer/PEBCommonSlice";
const { Search } = Input;

function SurveyQuestions() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.PEBCommon?.userDetail); // get User details
  const userId = userInfo.user_id;
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryDetails, setCategoryDetails] = useState("");
  const [surveyQuestionsList, setSurveyQuestionsList] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const tableRef = useRef();
  const columns = [
    {
      title: "ID",
      dataIndex: "ques_id",
      key: "ques_id",
      sorter: (event) => sortItems(event),
      width: 100,
      defaultSortOrder: 'ascend'
    },
    {
      title: "Question",
      dataIndex: "ques_text",
      key: "ques_text",
      width: 320,
    },
    {
      title: "Question Alias",
      dataIndex: "ques_alias",
      key: "ques_alias",
      width: 220,
    },
    {
      title: "Response Type",
      dataIndex: "response_type",
      key: "response_type",
      width: 150,
    },
    {
      title: "List of Values",
      dataIndex: "lov_list",
      key: "lov_list",
      width: 370,
      render: (_, lov) => (
        <>
          {lov.response_type === "Open" ? (
            <div style={{ color: "#9C9AA1" }}>Not Applicable</div>
          ) : (
            lov.lov_list?.map((formattedItem, index) => (
              <div style={{ paddingBottom: 6 }} key={index}>
                {index + 1}. {formattedItem}
              </div>
            ))
          )}
        </>
      ),
    },
    {
      title: "Category",
      dataIndex: "category_name",
      key: "category_name",
      sorter: (event) => sortItems(event),
      width: 150,
    },
  ];

  //API call for Survey Questions
  const loadMoreData = async () => {
    // if (loading || !hasMore) {
    //   return;
    // }
    setLoading(true);
    const payload = {
      user_id: userId,
      search_string: searchTerm || "",
      limit: 10,
      offset: currentPage * 10 - 10,
      sort_order: sortOrder === "descend" ? "DESC" : "ASC",
      sort_column: sortColumn || "ques_id",
    };

    try {
      const response = await getSurveyQuestions(payload);
      setCategoryDetails(response.categories_list);
      const newSurveyQuestionsList = response.questions_list;

      if (newSurveyQuestionsList.length < 10) {
        setHasMore(false);
        setSurveyQuestionsList((prevList) => [
          ...prevList,
          ...newSurveyQuestionsList,
        ]);
      } else {
        setHasMore(true);
        setSurveyQuestionsList((prevList) => [
          ...prevList,
          ...newSurveyQuestionsList,
        ]);
        setCurrentPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error fetching more data:", error);
    } finally {
      setLoading(false);
    }
  };

  const sortItems = (e) => {
   
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { column, order } = sorter;
    setCurrentPage(1);
    setSortOrder(order);
    setSortColumn(sorter.columnKey);
  };

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleScroll = debounce(() => {
    const tableBody = tableRef.current?.querySelector(".ant-table-body");
    // console.log("tableBody --",tableBody , tableBody.scrollHeight , tableBody.scrollTop , tableBody.clientHeight );
    if (tableBody) {
      const threshold = 2; // Adjust the threshold as needed
      const isBottom =
        tableBody.scrollHeight - tableBody.scrollTop - threshold <=
        tableBody.clientHeight;
      if (isBottom && hasMore) {
        loadMoreData();
      }
    }
  }, 200); // Adjust the debounce delay as needed

  useEffect(() => {
    const tableBody = tableRef.current?.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore, currentPage]);

  useEffect(() => {
    dispatch(setNavigationTab("/survey-questions"));
    const debounceId = setTimeout(() => {
      if (searchTerm.length > 2 || searchTerm.length === 0) {
        setSurveyQuestionsList([]);
        loadMoreData();
      }
    }, 1000);
    return () => clearTimeout(debounceId);
  }, [searchTerm, sortOrder]);

  const rowClassName = (record, index) => {
    return record.response_type === "Open"
      ? "row-class-highlight"
      : "row-class";
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  return (
    <div style={{ padding: 10, paddingBottom: 24 }}>
      <Spin indicator={antIcon} spinning={loading}>
        <Row>
          <Col span={24}>
            <p className="sub-header"> Survey Questions</p>
          </Col>

          <Col span={24}>
            <Row>
              <Col span={24}>
                <span className="sub-headers">Question Categories</span>
              </Col>
              <Divider />
              <Col span={24}>
                <List
                  size="large"
                  dataSource={categoryDetails}
                  renderItem={(item) => (
                    <List.Item>
                      <span className="category-span1 category-label">
                        {item.category_name}
                      </span>
                      <span className="category-span2 category-description">
                        {item.description}
                      </span>
                      <span className="category-span3"></span>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Divider style={{ paddingBottom: 32 }} />

          <Col style={{ marginRight: 16, marginTop: 8 }}>
            <span className="sub-headers">Questions List</span>
          </Col>
          <Col>
            <Space direction="vertical" style={{ float: "left" }}>
              <Search
                placeholder="Search Questions"
                onChange={onSearch}
                value={searchTerm} // Controlled input value
                size={"large"}
                enterButton={false}
                style={{ width: 300, borderRadius: "0px" }}
              />
            </Space>
          </Col>
        </Row>
        <div className="survey-table" ref={tableRef}>
          <Table
            dataSource={surveyQuestionsList}
            columns={columns}
            pagination={false}
            scroll={{
              y: 600,
            }}
            rowClassName={rowClassName}
            onChange={handleTableChange}
            onScroll={handleScroll}
          />
        </div>
      </Spin>
    </div>
  );
}

export default SurveyQuestions;
