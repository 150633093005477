import React, { useState, useEffect } from "react";
import AppRoutes from "../App.routes";
import "./Home.scss";
import { getUserInfo, logout } from "../services/ExperienceBankAPI";
import { Layout, Row, Col,Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { RDMenu } from "@zs-ca/react-cd";
import { setting, SideNavBarData, ProductLogo } from "./SideNavBarData";
import DataUpdates from "./data-updates/DataUpdates";
import HelpCenter from "../shared/help-center/HelpCenter";
import { setUserDetail, setNavigationTab } from "../reducer/PEBCommonSlice";
import Notification from "../shared/Notification/Notification.jsx";
import { useSelector, useDispatch } from "react-redux";
import { NotificationTypes } from "../shared/Notification/Notification.jsx";
import { setNotificationData } from "../reducer/PEBCommonSlice.js";
import { useLocation } from "react-router-dom";
import MaintenancePage from "../shared/MaintenancePage/MaintenancePage.jsx";
import ConcurrentSession from "./concurrent-session/ConcurrentSessionModal.jsx";
import LillyIcon from "../assets/icons/lilly-updated-icon.svg";
import GileadIcon from "../assets/icons/Gilead-logo.svg";
import ZSLogo from "../assets/icons/zs-logo-colored.svg";
import PEBGlobeIcon from "../assets/icons/globe-icon.svg";
const { Header, Content, Sider } = Layout;

function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const idmUrl = process.env.REACT_APP_IDM_URL;
  const bespokeInsightsRedirectURL = process.env.REACT_APP_ENV_SWITCH_URL;
  const dispatch = useDispatch();
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [userIdCheck, setUserIdCheck] = useState(true);
  const [userDetails, setUserDetails] = useState();
  const tabValue = useSelector((state) => state.PEBCommon?.navigationTab);
  const userInfo = useSelector((state) => state.PEBCommon?.userDetail);
  const analysisLockFlagStatus = useSelector(
    (state) => state.PEBCommon?.analysisLockFlag
  );
  localStorage.setItem("user_id", userInfo.user_id);
  const [tab, setTab] = useState(tabValue);
  const [sessionFlag, setSessionFlag] = useState(false);
  const environment = userInfo?.environment || "";
  const logoMap = {
    dev: "",
    qa: "",
    demo: "",
    prod_client_1: LillyIcon,
    prod_gild: GileadIcon,
    prod_aszn: "",
  };

  const clientLogo = logoMap[environment] || "";

  const redirectToLogin = () => {
    window.location.replace(`${idmUrl}`);
  };
  function getIdFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("id");
  }
  function setCookie(name, value, seconds) {
    const date = new Date();
    date.setTime(date.getTime() + seconds * 1000); // Convert seconds to milliseconds
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  useEffect(() => {
    var navigationType =
      window.performance.getEntriesByType("navigation")[0].type;

    if (navigationType == "back_forward") {
      sessionStorage.removeItem("sessionId");
    }
    const beforeUnloadHandler = async (event) => {
      // const loggedout = sessionStorage.getItem("loggedout");
      // if (loggedout != "yes" ) {
      //   // const message =
      //   //  "Are you sure you want to leave? Your changes may not be saved.";
      //   // window.confirm(message);
      //   // event.returnValue = message;
      // }
    };

    const handleUnload = async () => {
      try {
        // Call your API here to perform logout or any other actions
        const payload = {
          user_id: localStorage.getItem("user_id"),
          logout_from: "tab",
        };

        logout(payload)
          .then((res) => {
            window.close();
          })
          .catch((e) => console.log(e));

        // Close the window or tab
      } catch (error) {
        console.error("Error during logout:", error);
        // Handle error as needed
      }
    };

    // window.addEventListener("beforeunload", handleUnload);
    window.addEventListener("unload", handleUnload);
    return () => {
      // window.removeEventListener("beforeunload", handleUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  const userInfoFun = async () => {
    let response = await getUserInfo();
    const idFlag = response?.data?.maintenance_flag;
    setUserIdCheck(idFlag);
    if (response?.message === "Session Already Active") {
      setSessionFlag(true);
      localStorage.setItem("userInfo", JSON.stringify(response?.data));
      setUserAuthenticated(true);
      // navigate("/session");
      setTimeout(() => {}, 500);
      return true;
    } else if (response?.status === "SUCCEEDED") {
      dispatch(setUserDetail(response?.data));
      sessionStorage.setItem("sessionId", response?.data?.session_id);
      localStorage.setItem("userInfo", JSON.stringify(response?.data));
      setUserDetails(response?.data);
      setUserAuthenticated(true);
      setTimeout(() => {}, 500);
    } else {
      redirectToLogin();
      setTimeout(() => {}, 500);
    }
  };

  useEffect(() => {
    const id = getIdFromUrl();
    if (id && !sessionStorage.getItem("sessionId")) {
      setCookie("id", id, 10); // 'id' cookie expires in 30 seconds
    }
    if (window == window.top) {
      userInfoFun();
    }
  }, []);

  const [dataUpdatesFlag, setdataUpdatesFlag] = useState(false);
  const dataUpdatesDrawer = (state) => {
    setdataUpdatesFlag(state);
  };

  const onMenuChange = (e) => {
    if (e === "/bespoke-insights") {
      logOutFromPEB();
       setTimeout(() => {
        window.open(`${bespokeInsightsRedirectURL}`, "_self");
      }, 1200);
      return;
    }

    if (analysisLockFlagStatus && e === "/explore") {
      dispatch(
        setNotificationData({
          type: NotificationTypes.INFO,
          message:
            "Explore and other functionalities are not active for reports created before 06/09/2024.",
          placement: "topLeft",
        })
      );
    }

    if (
      !analysisLockFlagStatus ||
      e === "/survey-questions" ||
      e === "/analysis-library"
    ) {
      setTab(e);
      navigate(e);
      dispatch(setNavigationTab(e));
    }

  };

  const logOutFromPEB = async () => {
    const payload = {
      user_id: localStorage.getItem("user_id"),
      logout_from: "to_kural",
    };
    try {
      let response = await logout(payload);
    } catch (error) {
      console.log("Logout error ", error);
    }
  };

  const filterNavBarList = () => {
    return SideNavBarData.filter((item) => {
      // Remove "/bespoke-insights" based on the landing_visible is false
      if (!userDetails?.kural_visible && item.key === "/bespoke-insights") {
        return false;
      }
      return true;
    });
  };

  const handleSessionContinue = async () => {
    await userInfoFun(); // Re-call the login API
    setSessionFlag(false); // Hide the modal after successful session re-establish
  };


  const onHandleClose = ()=>{
    console.log("closeee")
    window.close();
  }
  return (
    <div>
      <Notification />
      {userAuthenticated && (
        <>
          {!userIdCheck ? (
            <Layout style={{ height: "100vh" }}>
              <Sider
                breakpoint="lg"
                collapsedWidth="0"
                className="side-nav-bar">
                <div className="demo-logo-vertical" />
                <div className="side-nav-bar-container" id="navbar-container">
                  <RDMenu
                    menuItems={ProductLogo}
                    mode="vertical"
                    style={{
                      width: 80,
                      background: "#2F2C3C",
                    }}
                  />

                  {location.pathname !== "/landing" && (
                    <RDMenu
                      menuItems={filterNavBarList()}
                      selectedOption={tabValue}
                      handleMenu={(e) => onMenuChange(e.key)}
                      mode="vertical"
                      style={{
                        width: 80,
                        background: "#2F2C3C",
                        minHeight: "40vh",
                      }}
                    />
                  )}

                  <RDMenu
                    menuItems={setting}
                    handleMenu={(e) => setTab(e.key)}
                    mode="vertical"
                    style={{
                      width: 80,
                      background: "#2F2C3C",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      bottom: 0,
                      position: "fixed",
                    }}
                  />
                </div>
              </Sider>
              <Layout style={{ background: "white" }}>
                {location.pathname !== "/landing" && (
                  <Header className="header-text1">
                    <Row style={{ height: 44 }}>
                      <Col span={12}>
                        <div className="header-text">
                        <img
                                src={PEBGlobeIcon}
                                alt="Lilly Logo"
                                className="header-logo"
                                style={{ height: 18, marginLeft:4 }}
                              />
                          <p className="title-text">Bank of Standards</p>
                          
                        </div>
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        <div className="logo-container">
                          {clientLogo && (
                            <>
                              <img
                                src={clientLogo}
                                alt="Lilly Logo"
                                className="header-logo"
                                style={{ height: 32 }}
                              />
                              <Divider
                                type="vertical"
                                style={{
                                  height: "24px",
                                  width: 2,
                                  backgroundColor: "#888B8D",
                                  verticalAlign: "super",
                                }}
                              />
                            </>
                          )}
                          <img
                            src={ZSLogo}
                            alt="ZS Logo"
                            className="header-logo"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Header>
                )}

                <Layout style={{ background: "white" }}>
                  <Content
                    style={{
                      borderTop: "1px solid  #DEDCDE",
                    }}>
                    <div
                      style={{
                        minHeight: 360,
                      }}>
                      <AppRoutes />
                    </div>
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          ) : (
            <div>
              <MaintenancePage />
            </div>
          )}

          {sessionFlag && (
            <ConcurrentSession
              onContinueSession={handleSessionContinue}
              onCancelSession={onHandleClose}
            />
          )}

          {tab === "helpCenter" ? <HelpCenter visible={true} /> : ""}
          {dataUpdatesFlag ? (
            <div>
              <DataUpdates
                visible={true}
                onClose={() => dataUpdatesDrawer(false)}
              />
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}

export default Home;
