import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import PatientExperience from "./components/patient-experience/PatientExperience";
import AnalysisLibrary from "./components/analysis-library/AnalysisLibrary";
import Explore from "./components/explore/Explore";
import Home from "./components/Home";
import SurveyQuestions from "./components/survey-questions/SurveyQuestions";
import LandingPage from "./components/landing-page/LandingPage";

function MissingRoute() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  // console.log("userInfo?.landing_visible ----", userInfo?.landing_visible);
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(userInfo?.landing_visible ? "/landing" : "/explore")
  }, []);

  return <div>404</div>
}

const ProtectedRoute = ({ children }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  // React.useEffect(() => {
  //   if (!userInfo?.session_id) {
  //     navigate("/home?h");
  //   }
  // }, []);
  return !userInfo?.session_id ? <div></div> : children;
};

function AppRoutes() {
  return (
    <Routes>
      <Route
        exact
        path="/analysis-library"
        element={
          <ProtectedRoute>
            <AnalysisLibrary />
          </ProtectedRoute>
        }
      />
      <Route path="/patient-experience" element={<PatientExperience />} />
      {/* <Route path="/explore" element={ <Explore />} /> */}
      <Route
        path="/explore"
        element={
          <ProtectedRoute>
            <Explore />
          </ProtectedRoute>
        }
      />
      <Route path="/home" element={<Home />} />
      {/* <Route path="/session" element={<ConcurrentSession />} /> */}
      <Route
        path="/survey-questions"
        element={
          <ProtectedRoute>
            <SurveyQuestions />
          </ProtectedRoute>
        }
      />
      <Route path="/landing" element={<LandingPage />} />
      <Route path="*" element={<MissingRoute />} />
    </Routes>
  );
}

export default AppRoutes;
